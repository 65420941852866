import { PLANS } from "src/CONSTANTS";

const { binary, matrix, monoLine, roi, uniLevel } = PLANS;

const options = [
  { value: "all", label: "amount_types.all" },
  // { value: "referral_bonus", label: "amount_types.r_bonus" },
  { value: "royalty_incentive", label: "Royalty Incentive" },
  // { value: "topup", label: "Top-Up" },

  { value: "performance_incentive", label: "Performance Incentive" },

  {
    value: "deducted_by_admin",
    label: "amount_types.deducted_by_admin",
  },
  {
    value: "credited_by_admin",
    label: "amount_types.credited_by_admin",
  },
  // { value: "fund_transfer", label: "amount_types.fund_transfer" },
  {
    plans: [roi],
    value: "roi_incentive",
    label: "Roi Incentive",
  },
  // {
  //   value: "first_order_bonus",
  //   label: "amount_types.first_order_bonus",
  // },
  {
    value: "level_incentive",
    label: "Level Incentive",
  },
  {
    plans: [binary],
    value: "binary_bonus",
    label: "amount_types.b_bonus",
  },
  {
    value: "rank_incentive",
    label: "Rank Incentive",
  },
  {
    plans: [binary],
    value: "binary_matching_bonus",
    label: "amount_types.m_bonus",
  },
];

export default options;
